:root {
    /* Fonts */
    --fontFamily-system: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
        Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";

    --fontFamily-serif: Rockwell, Bookman, "Bookman Old Style", georgia, times, serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    --fontFamily-mono: "Noto Mono", "Roboto Mono", "Source Code Pro", "Droid Sans Mono", "DejaVu Sans Mono", Monaco,
        "Lucida Console", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    /* Colors */
    --baseColor-brand-1: 272, 100%, 50%;
    --color-brand-1: hsl(var(--baseColor-brand-1));
    --baseColor-brand-2: 305, 78%, 35%;
    --color-brand-2: hsl(var(--baseColor-brand-2));
    --baseColor-brand-3: 265, 60%, 16%;
    --color-brand-3: hsl(var(--baseColor-brand-3));
    --baseColor-brand-4: 272, 100%, 80%;
    --color-brand-4: hsl(var(--baseColor-brand-4));

    --baseColor-bg: 233, 65%, 10%;
    --color-bg: hsl(var(--baseColor-bg));
    --baseColor-fg: 237, 43%, 16%;
    --color-fg: hsl(var(--baseColor-fg));

    --color-footer: hsl(238, 29%, 23%);
    --color-tableOdd: hsla(0, 0%, 100%, 0.08);

    /* Colors- Text */
    --baseColor-text: 0, 0%, 100%;
    --color-text: hsl(var(--baseColor-text));
    --baseColor-text-inv: 0, 0%, 0%;
    --color-text-inv: hsl(var(--baseColor-text-inv));

    /* Tones */
    --color-neutral-100: hsl(0, 0%, 100%);
    --color-neutral-200: hsl(0, 0%, 90%);
    --color-neutral-400: hsl(0, 0%, 75%);
    --color-neutral-600: hsl(0, 0%, 50%);
    --color-neutral-800: hsl(0, 0%, 25%);
    --color-neutral-900: hsl(0, 0%, 0%);

    --color-border: hsla(0, 0%, 0%, 0.3);

    /* Colors - Status */
    --baseColor-danger: 0, 70%, 70%;
    --color-danger: hsl(var(--baseColor-danger));
    --baseColor-warning: 43, 56%, 62%;
    --color-warning: hsl(var(--baseColor-warning));
    --baseColor-success: 121, 69%, 46%;
    --color-success: hsl(var(--baseColor-success));

    /* Colors - Grad */
    --color-grad1: hsl(262, 100%, 36%);
    --color-grad2: hsl(288, 87%, 38%);
    --color-grad3: hsl(359, 65%, 42%);
    --gradient-bar: var(--color-grad1), var(--color-grad2), var(--color-grad3);

    /* Colors - Graph */
    --color-graph0: #8435f2;
    --color-graph1: #4900ff;
    --color-graph2: #fdc3ff;
    --color-graph3: #f06bf2;
    --color-graph4: #ab1d6b;
    --color-graph5: #ff1d6b;
    --color-graph6: #ff81ad;
    --color-graph7: #255b84;
    --color-graph8: #0085d9;
    --color-graph9: #72f2f2;
    --color-graph10: #006249;
    --color-graph11: #00b335;
    --color-graph12: #a5ff50;
    --color-graph13: #f2d98d;
    --color-graph14: #ffe000;
    --color-graph15: #da8d00;
    --color-graph16: #f2561d;
    --color-graph17: #9e4526;
    --color-graph18: #c2c4c6;
    --color-graph19: #7f7e83;

    /* Utils */
    --card-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    --card-radius: 12px;
    --card-shine: radial-gradient(ellipse at top, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    --float-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
    --interactableRadius: 6px;
    --button-textAlign: center;

    /* Inputs */
    --input-minWidth: 220px;
}

/* Breakpoints */
@custom-media --floatingNav (max-width: 600px);
@custom-media --asidePanelRight (min-width: 1200px);

/* Light color vars */
/* [data-theme="light"] {
    
} */
