.keyPairTable {
}

.keyPairTable_striped > :nth-child(odd) {
    background: var(--color-tableOdd);
}

.keyPairTable_striped .keyPairTable-line {
    padding: 0.8em;
    &:last-child {
        padding-bottom: 0.8em;
        &.keyPairTable-line_large {
            padding-bottom: 1.2em;
        }
    }
    &.keyPairTable-line_divide {
        padding-top: 0.8em;
    }
}

.keyPairTable-line {
    padding: 0 0 1.2em;
    display: grid;
    grid-template-columns: 1fr;
    & > dt:after {
        content: ":";
    }
    & > dd {
        text-align: right;
    }
    @media (min-width: 400px) {
        grid-template-columns: max-content 1fr;
        grid-gap: 8px;
    }
    &:last-child {
        padding-bottom: 0;
    }
    &.keyPairTable-line_large {
        font-size: 1.4em;
    }
    &.keyPairTable-line_divide {
        padding-top: 1.2em;
        border-top: 1px solid var(--color-brand-1);
    }
    &.keyPairTable-line_bold {
        font-weight: bold;
    }
}
