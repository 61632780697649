.tooltip {
    --borderColor: var(--color-brand-2);
    --backgroundColor: var(--color-fg);
    color: var(--color-text);
    position: absolute;
    top: 0;
    left: 0;
    max-width: 95vw;
    border-radius: 8px;
    z-index: 10;
    padding: 16px;
    filter: drop-shadow(0 8px 6px rgba(0, 0, 0, 0.4));

    opacity: 0;
    transform: translate(0, 0);
    transition: ease 400ms;
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.28, 2.01, 0.62, 0.78);
    pointer-events: none;
    &.is-open {
        opacity: 1;
        pointer-events: initial;
    }
    & > svg {
        z-index: -1;
        pointer-events: none;
        position: absolute;
        inset: 0;
    }
}

.tooltip_up {
    transform: translate(0, -8px);
    &.is-open {
        transform: translate(0, -12px);
    }
}

.tooltip_down {
    transform: translate(0, 8px);
    &.is-open {
        transform: translate(0, 12px);
    }
    & > svg {
        top: auto;
    }
}

.tooltip_right {
    transform: translate(8px, 0);
    &.is-open {
        transform: translate(12px, 0);
    }
    & > svg {
        left: auto;
    }
}

.tooltip_left {
    transform: translate(-8px, 0);
    &.is-open {
        transform: translate(-12px, 0);
    }
}
