.list {
    overflow: hidden;
}

.list-scroll {
    border-bottom: 1px solid var(--color-brand-2);
    border-top: 1px solid var(--color-brand-2);
    width: 100%;
    position: relative;
}

.list-list {
}

.list-virtItem {
    display: flex;
    overflow: clip;
    min-width: min-content;
    /* This padding is copied from Scroller which should have inherited but for some reason doesn't correctly */
    padding-right: calc(var(--scrollbar-offset) - var(--scrollbar-size, 0px));
}

.list-header > .list-virtItem {
    align-items: center;
}

.list-header,
.list-footer {
    /* This fixes a dumb layout issue, the children just overflow and are still visible */
    width: 1px;
    min-width: 100%;
}

.list-cell {
    flex-shrink: 0;
    flex-grow: 1;
    min-width: max-content;
    padding: 8px;
    text-align: right;
    transition: opacity 200ms ease 200ms, min-width 200ms ease;
    display: grid;
    align-items: center;
    justify-content: end;
    white-space: nowrap;
    &.list-cell_odd {
        background: var(--color-tableOdd);
    }
    &[data-key-index="0"] {
        text-align: left;
        justify-content: start;
    }
    &:last-child {
        padding-right: 16px;
    }
}

.is-cacheInit .list-cell {
    opacity: 0;
}

.list-sortBtn {
    display: block;
    &:before,
    &:after {
        content: "";
        border-width: 6px 4px 0;
        border-color: currentColor transparent transparent;
        border-style: solid;
        display: inline-block;
        width: 0;
        height: 0;
        margin-right: 1ch;
        transition: opacity ease 1ms, transform cubic-bezier(0.35, 1.84, 0.5, 0.84) 1ms;
        transition-duration: 200ms, 400ms;
        transition-delay: 0ms, 0ms;
        opacity: 0;
        transform: rotate(0.5turn);
    }
    &:after {
        margin-left: 1ch;
        margin-right: 0;
    }
    &.is-sorted:before,
    &.is-sorted:after {
        opacity: 1;
    }
    &.is-asc:before,
    &.is-asc:after {
        transform: rotate(0turn);
    }
    &[data-key-index="0"]:before {
        content: none;
    }
    &:not([data-key-index="0"]):after {
        content: none;
    }
}

.list-fullWidth {
    position: absolute;
    width: 100%;
}
