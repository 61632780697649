.labeledInput {
    display: grid;
}

.labeledInput-input {
    & > :not(.rah-static--height-zero) {
        margin-top: 8px;
    }
    /* This is a little messy to have here, but ranges need special treatment when labeled */
    & > .range {
        margin-top: 14px;
    }
}

.labeledInput-label {
    display: block;
    font-weight: bold;
    font-size: 0.9em;
}

.labeledInput-hint,
.labeledInput-error {
    text-align: left;
    line-height: 1.2;
}

.labeledInput-hint {
    color: hsla(var(--baseColor-text), 0.7);
}

.labeledInput-error {
    color: var(--color-danger);
    font-weight: bold;
    padding-bottom: 4px;
}

.labeledInput_checkbox .labeledInput-label {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 16px;
    align-items: center;
    text-transform: none;
    font-weight: normal;
    font-size: 1em;
    letter-spacing: 0;
    line-height: 1.3;
    margin: 0;
}

.labeledInput-optional {
    opacity: 0.8;
    font-size: 0.8em;
}
