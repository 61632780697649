.spinner {
    animation-name: spinnerExpand, spinnerSpin;
    animation-duration: 2s, 1s;
    animation-timing-function: cubic-bezier(0.3, 0, 0.8, 1), linear;
    animation-iteration-count: infinite;
    transform-origin: center center;
    display: inline-block;
    vertical-align: middle;
    @media (prefers-reduced-motion) {
        animation-name: spinnerSpin;
        animation-duration: 10s;
        animation-timing-function: linear;
        stroke-dasharray: var(--pathLength50) var(--pathLength50);
    }
}

@keyframes spinnerExpand {
    from,
    to {
        stroke-dasharray: var(--pathLength10) var(--pathLength90);
    }
    50% {
        stroke-dasharray: var(--pathLength60) var(--pathLength40);
    }
}

@keyframes spinnerSpin {
    to {
        transform: rotate(360deg);
    }
}

.spinner.is-pending {
    animation-duration: 5s, 2.5s;
    animation-direction: reverse;
    opacity: 0.7;
}
