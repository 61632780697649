.button {
    background: none;
    border: 1px solid var(--color-brand-1);
    color: var(--color-text);
    margin: 0;
    padding: 12px 32px;
    cursor: pointer;
    transition: 300ms ease;
    user-select: none;
    font: inherit;
    font-size: 1rem;
    display: inline-block;
    text-align: var(--button-textAlign, center);
    text-decoration: none;
    border-radius: 999px;
    outline: 2px solid transparent;
    overflow: hidden;
    position: relative;
    /* This fixes a bug in Chrome with overflow hidden + border-radius and the splash animation (1x1 black pixel) */
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
    &:focus {
        outline: 2px solid currentColor;
        outline-offset: -4px;
        &:not(:-moz-focusring),
        &:not(:focus-visible) {
            outline: 0 solid transparent;
        }
    }
    &[disabled]:not(.button_pending) {
        background: var(--color-neutral-800);
        color: var(--color-neutral-400);
        border-color: var(--color-border);
        cursor: not-allowed;
    }
    &[disabled] {
        /* Prevents the splash effect while disabled */
        pointer-events: none;
    }
    &:hover {
        transition-duration: 150ms;
    }
}

.is-mouse .button:focus {
    outline-color: transparent;
}

/* Types */

.button_cta {
    color: var(--color-text-inv);
    background: #ee7422;
    border-color: transparent;
}

.button_mono {
    color: var(--color-text);
    border-color: var(--color-text);
}

.button_danger {
    color: var(--color-danger);
    border-color: var(--color-danger);
}

.button_clear {
    background: none;
    box-shadow: none;
    border: 0;
    color: inherit;
    padding: 12px;
    overflow: visible;
    -webkit-mask-image: unset;
    &[disabled]:not(.button_pending) {
        color: var(--color-neutral-600);
        background: none;
    }
    &.button_large {
        padding: 16px;
    }
}

.button_link {
    box-shadow: none;
    padding: 0;
    display: inline;
    user-select: auto;
    text-decoration: underline;
    color: var(--color-text);
    font-size: inherit;
    border: 0;
    border-radius: 0;
    overflow: initial;
    -webkit-mask-image: unset;
    &:focus {
        outline-offset: 4px;
    }
    &[disabled]:not(.button_pending) {
        color: var(--color-neutral-600);
        text-decoration: none;
        background: none;
    }
    &.button_pending {
        padding: 0;
        & > .spinner {
            position: relative;
            margin-right: 8px;
            left: 0;
        }
    }
    &.button_large {
        padding: 0;
    }
}

/* Modifiers */

.button_block {
    display: block;
    width: 100%;
}

.button_pending {
    padding-left: 48px;
    & > .spinner {
        position: absolute;
        top: calc(50% - 8px);
        left: 16px;
    }
    &.button_large {
        padding-left: 64px;
        & > .spinner {
            top: calc(50% - 16px);
        }
    }
}

.button_large {
    font-size: 1.4rem;
    padding: 16px 48px;
}

/* Splash */

.button-splash {
    position: absolute;
    top: calc(var(--click-y, -9999) * 1px);
    left: calc(var(--click-x, -9999) * 1px);
    border-radius: 999px;
    width: 20px;
    height: 20px;
    background: currentColor;
    opacity: 0;
    transform: translate(-50%, -50%) scale(6);
    transition: 400ms ease-out;
    transition-property: transform, opacity;
    mix-blend-mode: overlay;
    pointer-events: none;
}

.button:active .button-splash {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.6;
    transition: 1ms;
}
