.toast {
    padding: 16px 48px 16px 16px;
    border-left: 1px solid var(--color-brand-1);
    background: var(--color-brand-3);
    overflow: 0;
    min-height: 32px;
    position: relative;
    display: inline-block;
    text-align: left;
    max-width: 60vw;
    overflow: clip;
    pointer-events: initial;
    --autoDismiss: 0;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 3px;
        left: 0;
        width: 100%;
        background: var(--color-brand-2);
        opacity: 0.5;
        transform-origin: left center;
        animation: toast-timer linear 1 calc(var(--autoDismiss, 0) * 1ms);
        animation-fill-mode: forwards;
    }

    transition: ease-in 400ms;
    opacity: 1;
    transform: rotate(0deg);
    &.is-dismissed {
        opacity: 0;
        transform: rotate(-20deg);
    }
}

.toast_success {
    padding-left: 32px;
    --color-brand-1: var(--color-success);
    --color-brand-2: var(--color-success);
}

.toast_error {
    padding-left: 32px;
    --color-brand-1: var(--color-danger);
    --color-brand-2: var(--color-danger);
}

@keyframes toast-timer {
    from {
        transform: scaleX(1);
    }
    to {
        transform: scaleX(0);
    }
}

.toast\~root {
    position: absolute;
    z-index: 999;
    bottom: 0;
    right: 0;
    text-align: right;
    padding: 16px;
    pointer-events: none;
    @media (min-width: 600px) {
        padding: 32px;
    }
    &:before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        pointer-events: none;
        background: rgba(0, 0, 0, 0.4);
        filter: blur(40px);
        height: 100vh;
        width: 150%;
        transform-origin: center top;
        transition: 400ms ease;
        opacity: 0;
    }
    &:not(.is-empty) {
        &:before {
            opacity: 1;
        }
    }
}

.toast-dismiss {
    position: absolute;
    top: 4px;
    right: 4px;
    color: var(--color-brand-2);
}

.toast-title {
    font-weight: bold;
    font-size: 1.1em;
    padding-bottom: 16px;
}

.toast-icon {
    position: absolute;
    left: 8px;
    color: var(--color-brand-2);
    top: 50%;
    transform: translateY(-50%);
}
