.aspectRatioIframe {
    display: block;
    position: relative;
}

.aspectRatioIframe-iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
