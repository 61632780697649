.countryInput {
}

.countryInput-set {
    display: grid;
    grid-gap: 8px 48px;
    grid-template-columns: repeat(auto-fit, 170px);
}
.countryInput-set_inset {
    padding: 0 16px;
}

.countryInput-country {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 0 8px;
}

.countryInput-selectors {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* Floating these checkboxes over the titles, it's fairly magic number heavy
   but placing the the input within the title element causes bugs in the expanding area */

.countryInput-setCheckbox {
    position: absolute;
    top: 12px;
    left: 4px;
    z-index: 2;
}

.countryInput-heading {
    padding-left: 24px;
}
