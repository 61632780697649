.sortInput-row {
    --_input-border-color: var(--input-border-color, var(--color-brand-1));
    --_input-border-color-active: var(--input-border-color-active, var(--color-brand-2));
    --_input-background-color: var(--input-background-color, var(--color-bg));

    background: var(--_input-background-color);
    border: 1px solid var(--_input-border-color);
    border-radius: var(--interactableRadius);
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: clip;

    .sortInput:not(.is-disabled) & {
        &:active {
            cursor: grabbing;
        }

        &::before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 16px;
            background-image: radial-gradient(circle at 50% 50%, hsla(0deg, 0%, 100%, 0.2) 35%, transparent 38%);
            background-size: 6px 6px;
            background-repeat: round;
            margin-left: 4px;
            cursor: grab;
        }
    }

    &.sortInput-row_add {
        background: transparent;
        &::before {
            opacity: 0;
        }
        &:not(.is-disabled):active {
            cursor: initial;
        }
    }

    &.react-grid-item.cssTransforms {
        /* Constraining the grid libary to not animate w/h, as it isn't needed here */
        transition-property: transform;
    }
}

.sortInput-label {
    flex-grow: 1;
    padding: 0 8px;
}

.sortInput-spacer {
    height: 32px;
}
