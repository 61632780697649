.infoBox {
    padding: 16px;
    background: var(--color-brand-3);
    border-left: 1px solid var(--color-brand-2);
    position: relative;
}

.infoBox-dismiss {
    position: absolute;
    top: 4px;
    right: 4px;
    color: var(--color-brand-2);
}

.infoBox-title {
    font-weight: bold;
    font-size: 1.1em;
    padding-bottom: 16px;
}
