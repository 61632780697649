.breadcrumbs {
    display: flex;
    gap: 16px;
    align-items: center;
}

.breadcrumbs-nav {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 8px 0;
    font-size: 0.9em;
}

.breadcrumbs-crumb {
    color: var(--color-brand-4);
    & + .breadcrumbs-crumb:before {
        content: "/";
        padding-right: 12px;
        color: hsla(var(--baseColor-text), 0.6);
        text-decoration: none;
        pointer-events: none;
    }
    &.breadcrumbs-crumb_active {
        color: hsla(var(--baseColor-text), 0.8);
    }
}
