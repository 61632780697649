/* CSS Reset */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

nav ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
}

input,
select {
    vertical-align: middle;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    @media (prefers-reduced-motion) {
        /* This could be a little aggressive... */
        transition-duration: 0ms !important;
    }
}

input,
input[type],
select,
select:focus,
textarea {
    font-size: inherit;
}

select:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill {
    /* Remove yellow bg in Chrome autofilled inputs */
    -webkit-box-shadow: 0 0 0 500px var(--color-bg) inset;
    background-clip: content-box;
    -webkit-text-fill-color: var(--color-text) !important;
}
