.hr.hr {
    --hr-color: var(--color-brand-1);
    border-color: var(--hr-color);
    border-style: solid;
    border-width: 0 0 1px;
    margin: 16px 0;
    width: 100%;
    height: 0;
}

.hr.hr_vertical {
    width: 0;
    min-height: 100%;
    min-height: max(1lh, 100%);
    height: auto;
    border-width: 0 1px 0 0;
    margin: 0 16px;
    display: inline-block;
    align-self: stretch;
}
