.input {
    --_input-border-color: var(--input-border-color, var(--color-brand-1));
    --_input-border-color-active: var(--input-border-color-active, var(--color-brand-2));
    --_input-background-color: var(--input-background-color, var(--color-bg));
    appearance: none;
    display: block;
    max-width: none;
    min-width: 0;
    width: 100%;
    font: inherit;
    color: var(--color-text);
    background: var(--_input-background-color);
    border: 1px solid var(--_input-border-color);
    padding: 8px;
    text-align: left;
    position: relative;
    transition: 200ms ease;
    outline: 0;
    line-height: 1.4;
    border-radius: var(--interactableRadius);
    box-shadow: none;
    margin: 0;
    min-width: var(--input-minWidth);
    &.is-invalid {
        border-color: var(--color-danger);
        outline: 2px solid var(--color-danger);
        outline-offset: 2px;
    }
    &:focus,
    &:focus-within {
        transition-duration: 100ms;
        border-color: var(--_input-border-color-active);
    }
    &[disabled],
    &.is-disabled {
        &:not(.is-readOnly) {
            background: var(--color-neutral-800);
            border-color: var(--color-neutral-900);
            color: hsla(var(--baseColor-text), 0.5);
        }
    }
    &::placeholder {
        color: var(--color-brand-4);
        opacity: 0.7;
    }
}

/* ----------------- */

.input-facade {
    position: absolute;
    inset: 0;
    width: 100%;
    opacity: 0.01;
    z-index: 5;
}

.input-facade_pointer {
    cursor: pointer;
}

.input-btn {
    position: absolute;
    width: 32px;
    height: 100%;
    right: 4px;
    top: 0;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    outline: 0;
    cursor: pointer;
    transition: 200ms ease;
    outline: 2px solid transparent;
    outline-offset: 2px;
    z-index: 6;
    opacity: 0.6;
    color: inherit;
    border-radius: var(--interactableRadius);
    &:focus-visible {
        outline: 2px solid currentColor;
        outline-offset: -2px;
    }
    &:hover:not([disabled]) {
        opacity: 1;
    }
    & svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.input-btn_clear {
    display: none;
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60%;
        height: 2px;
        background: currentColor;
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.is-filled > .input-btn_clear {
    display: inline-block;
}

.is-filled > .input-btn_file {
    right: calc(32px + 4px);
}
