.login-btns {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
}

.login-btns_reverse {
    flex-direction: row-reverse;
    justify-content: flex-start;
}
