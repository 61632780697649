.spinnerOverlay {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: hsla(var(--baseColor-bg), 0.8);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.spinnerOverlay_static {
    position: relative;
    padding: 16px;
    height: auto;
    background: none;
}

.spinnerOverlay_clear {
    background: none;
}

.spinnerOverlay-text {
    display: block;
    padding-top: 16px;
}
