.range {
    --fill-percent: 0;
    --fill-percentage: calc(var(--fill-percent, 0) * 1%);
    --fill-multiplier: calc(var(--fill-percent, 0) / 100);
    --fill-multiplier-inv: calc(1 - var(--fill-multiplier, 0));
    --range-transition: 50ms linear;
    --range-head-size: 16px;
    margin: 4px 0;
    display: block;
    position: relative;
    border-radius: 99px;
    height: 4px;
    background: var(--color-neutral-600);
    &.is-invalid {
        outline: 2px solid var(--color-danger);
        outline-offset: 6px;
    }
}

.range-bar {
    position: absolute;
    background: var(--color-brand-1);
    border-radius: 99px;
    top: -2px;
    bottom: -2px;
    left: 0;
    width: calc(var(--fill-percentage) + (var(--range-head-size) * var(--fill-multiplier-inv)));
    transition: var(--range-transition);
}

.range-head {
    width: var(--range-head-size);
    height: var(--range-head-size);
    background: var(--color-text);
    border: 2px solid var(--color-brand-1);
    position: absolute;
    top: 50%;
    left: var(--fill-percentage, 0%);
    transform: translate(calc(var(--fill-percentage, 50%) * -1), -50%);
    border-radius: 99px;
    transition: var(--range-transition);
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.2);
    &:before {
        content: attr(data-percent);
        position: absolute;
        bottom: calc(var(--range-head-size) + 4px);
        text-align: center;
        left: 50%;
        transform: translate(-50%, 50%);
        opacity: 0;
        pointer-events: 0;
        transition: ease 200ms;
    }
}

.range-input {
    margin: 0;
    height: calc(100% + 8px);
    inset: -4px 0;
}

.range-input:focus-visible ~ .range-head {
    outline: 2px solid var(--color-text);
    outline-offset: 2px;
}

.range-input:active,
.range-input:focus-visible {
    & ~ .range-head:before {
        opacity: 1;
        transform: translate(-50%, 0%);
        transition: ease 300ms;
    }
}

.range:not(.is-readOnly) .range-input[disabled] {
    & ~ .range-head {
        border-color: var(--color-neutral-800);
    }
    & ~ .range-bar {
        background: var(--color-neutral-800);
    }
}

.range_labelBelow .range-head:before {
    bottom: auto;
    top: calc(var(--range-head-size) + 4px);
    transform: translate(-50%, -50%);
}
