.cookiePopover {
    position: fixed;
    inset: auto 16px 16px;
    background: var(--color-fg);
    background-image: var(--card-shine);
    padding: 32px;
    box-shadow: var(--float-shadow);
    border-radius: var(--card-radius);
    z-index: 20;
    transform: translateY(150%) rotate(-5deg);
    transform-origin: right center;
    transition: 300ms ease;
    transition-timing-function: cubic-bezier(0.47, -0.5, 1, 0.27);
    @supports (translate: 0 15px) {
        transition: transform ease 0ms 400ms;
        animation-name: cookieHideTrans, cookieHideRot;
        animation-duration: 400ms;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.47, -0.5, 1, 0.27), cubic-bezier(0.47, -1.5, 0.42, 1.01);
    }
    @media (min-width: 600px) {
        left: auto;
        width: 500px;
    }
    &.is-open {
        transition-delay: 0ms;
        transition-duration: 400ms;
        transition-timing-function: cubic-bezier(0.24, 1.78, 0.6, 0.83);
        transform: translateY(0) rotate(0deg);
        animation: none;
    }
    & h2 {
        margin-bottom: 16px;
        font-size: 1.1em;
        font-weight: bold;
    }
}

@keyframes cookieHideTrans {
    0% {
        translate: 0 0;
    }
    100% {
        translate: 0 150%;
    }
}
@keyframes cookieHideRot {
    0% {
        rotate: 0;
    }
    50%,
    100% {
        rotate: 4deg;
    }
}

.cookiePopover-btns {
    display: grid;
    gap: 16px;
    margin-top: 24px;
    @media (min-width: 500px) {
        grid-template-columns: 1fr 1fr;
    }
}
