.radio {
    position: relative;
    display: inline-block;
}

.radio-el {
    position: relative;
    border: 1px solid var(--color-brand-1);
    width: 24px;
    height: 24px;
    background: var(--color-bg);
    display: inline-block;
    vertical-align: top;
    outline: 1px solid transparent;
    outline-offset: 4px;
    border-radius: 999px;
    transition: 200ms ease;
    &:after {
        content: "";
        position: absolute;
        inset: 0;
        background: transparent;
        transform: scale(0);
        transition: 200ms ease-out;
        border-radius: inherit;
    }
}

.is-checked > .radio-el:after {
    transform: scale(0.45);
    background: var(--color-text);
    transition: 300ms cubic-bezier(0.28, 2.01, 0.62, 0.78);
}

input:focus + .radio-el {
    border-color: var(--color-brand-2);
}

.is-invalid > .radio-el {
    border-color: var(--color-danger);
    outline: 2px solid var(--color-danger);
    outline-offset: 2px;
}

.is-disabled > .radio-el {
    background: var(--color-neutral-800);
    border-color: var(--color-neutral-900);
}

.is-disabled.is-checked > .radio-el:after {
    background: var(--color-neutral-900);
}

.radio-input {
    position: absolute;
    top: 0;
    right: 200vw;
    width: 1px;
    height: 1px;
    opacity: 0.1;
}
