.scroller.scroller {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &.scroller_autoHeight {
        height: auto;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 10%;
        width: 80%;
        z-index: 2;
        height: 32px;
        border-radius: 100%;
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.4);
        opacity: 0;
        transition: ease 250ms;
    }
    &:after {
        bottom: auto;
        top: 100%;
        box-shadow: 0 -8px 32px 0 rgba(0, 0, 0, 0.4);
    }
    &.is-scrollUp:before,
    &.is-scrollDown:after {
        opacity: 1;
    }
}

.scroller-view {
    position: absolute;
    inset: 0px;
    overflow: scroll;
    overscroll-behavior: contain;
}
.scroller_autoHeight > .scroller-view {
    position: relative;
    min-width: 100%;
    min-height: calc(var(--scroll-min-height) + var(--scrollbar-offset));
    max-height: calc(var(--scroll-max-height) + var(--scrollbar-offset));
    height: calc(var(--scroll-height) + var(--scrollbar-offset));
}
.scroller_virtualised > .scroller-view {
    overflow-anchor: none;
}

.scroller-view {
    --scrollbar-offset: 30px;
    margin-right: calc(var(--scrollbar-offset) * -1);
    margin-bottom: calc(var(--scrollbar-offset) * -1);
    & > .scroller-child {
        /* Using border here so we don't mess up the normal padding */
        border: calc(var(--scrollbar-offset) - var(--scrollbar-size, 0px)) solid transparent;
        border-top: 0;
        border-left: 0;
    }
}

.scroller-child {
    padding: var(--scroll-padding, 12px);
    min-height: 100%;
    min-width: min-content;
}

.scroller-track {
    position: absolute;
    background-color: hsla(var(--baseColor-text), 0.1);
    border-radius: 99px;
    display: none;
}

.scroller-thumb {
    position: absolute;
    background-color: hsla(var(--baseColor-text), 0.8);
    border-radius: inherit;
    border-radius: 99px;
    box-shadow: 0 0 4px hsla(var(--baseColor-text-inv), 0.5);
}

.scroller.is-vertical > .scroller-track_v {
    display: block;
}
.scroller.is-horizontal > .scroller-track_h {
    display: block;
}

.scroller-track_h {
    inset: auto 12px 2px;
    height: 6px;
    & .scroller-thumb {
        height: 100%;
    }
}
.scroller-track_v {
    inset: 2px 2px 2px auto;
    width: 6px;
    & .scroller-thumb {
        width: 100%;
    }
}
