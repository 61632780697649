.errorMessage {
    padding: 4px 16px 4px 8px;
    border: var(--color-danger) 1px solid;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    & > svg {
        color: var(--color-danger);
        margin-right: 8px;
        min-width: 24px;
    }
}

.errorMessage-msg {
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 8px 0;
    line-height: 1.3;
}

.errorMessage_warn {
    border-color: var(--color-warning);
    & > svg {
        color: var(--color-warning);
    }
}
