.arrayInput {
    &.is-invalid {
        & .arrayInput-wrap {
            outline: 2px solid var(--color-danger);
            outline-offset: 2px;
            border-radius: var(--interactableRadius);
        }
        & .arrayInput-scrollOuter,
        & .arrayInput-input {
            border-color: var(--color-danger);
        }
    }
    &.arrayInput_removeOnly {
        & .arrayInput-scrollOuter {
            border-top: 1px solid var(--color-brand-1);
            border-radius: var(--interactableRadius);
        }
        &.is-invalid .arrayInput-scrollOuter {
            border-top-color: var(--color-danger);
        }
    }
}

.arrayInput-inputWrap {
    position: relative;
}

.arrayInput-input {
    padding-right: 32px;
    border-radius: var(--interactableRadius) var(--interactableRadius) 0 0;
    &[disabled] {
        border-color: var(--color-brand-1);
    }
}

.arrayInput-scrollOuter {
    border: 1px solid var(--color-brand-1);
    border-top: 0;
    background: var(--color-bg);
    position: relative;
    border-radius: 0 0 var(--interactableRadius) var(--interactableRadius);
}

.arrayInput-scrollInner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    align-items: flex-start;
    gap: 12px;
    &.is-empty {
        grid-template-columns: 1fr;
    }
    /* Bit of a hack to sort out a scroll spacing issue */
    & > :last-child {
        padding-bottom: 16px;
    }
}

.arrayInput-word {
    display: flex;
    align-items: center;
    background: hsla(0, 0%, 100%, 0.2);
    padding: 4px 8px 4px 18px;
    border-radius: 999px;
    justify-content: space-between;
    word-break: break-word;
    overflow: hidden;
    & > :last-child {
        margin-left: 8px;
    }
}
