.expandableArea {
    &.is-open .expandableArea-title:after {
        transform: translateY(-50%) rotate(-135deg);
    }
}

.expandableArea-title {
    border: none;
    margin: 0;
    padding: 16px;
    border-bottom: 1px solid var(--color-brand-1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font: inherit;
    text-align: left;
    font-size: 1.2em;
    position: relative;
    padding-right: 32px;
    background: none;
    color: inherit;
    &:after {
        content: "";
        top: 50%;
        right: 16px;
        width: 8px;
        height: 8px;
        border-width: 0 2px 2px 0;
        border-color: transparent currentColor currentColor transparent;
        border-style: solid;
        position: absolute;
        transform: translateY(-50%) rotate(45deg);
        transition: 400ms ease;
        transition-timing-function: cubic-bezier(0.54, 1.57, 0.53, 0.96);
    }
    &:focus {
        outline: 1px solid currentColor;
        outline-offset: -1px;
        &:not(:-moz-focusring),
        &:not(:focus-visible) {
            outline: 0;
        }
    }
}

.expandableArea-body {
    padding: 16px;
}
