.hintIcon {
    text-decoration: none;
    vertical-align: top;
    color: hsla(var(--baseColor-text), 0.7);
    text-align: center;
    user-select: none;
    margin: 0 -20px 0 8px;
    margin: 0 calc(-0.5ch - 12px) 0 0.5ch;

    &.hintIcon_inline {
        margin: 0 8px;
        margin: 0 0.5ch;
    }
}

.hintIcon-menu {
    max-width: 600px;
}

.hintIcon-title {
    font-weight: bold;
    margin-bottom: 16px;
}

.hintIcon-text {
    line-height: 1.3;
    & > p:not(:last-child) {
        margin-bottom: 8px;
    }
}
