.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0;
    z-index: 100;
    padding: 16px;
    pointer-events: none;
    &.is-open {
        pointer-events: initial;
    }
}

.modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(var(--baseColor-bg), 0);
    z-index: 1;
    transition: ease 200ms;
}

.modal.is-open .modal-backdrop {
    background: hsla(var(--baseColor-bg), 0.6);
}

.modal-frame {
    background: var(--color-fg);
    background-image: var(--card-shine);
    border-radius: var(--card-radius);
    z-index: 2;
    box-shadow: 0 6px 6px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    max-width: 800px;
    max-height: 95vh;
    position: relative;
    transition: opacity ease 200ms 200ms, transform ease 400ms;
    transition-timing-function: ease, cubic-bezier(0.47, -1.5, 1, 0.27);
    opacity: 0;
    transform: translateY(42px) rotate(-3deg);
    transform-origin: right center;
    @supports (translate: 0 15px) {
        transition: opacity ease 200ms 200ms, transform ease 0ms 400ms;
        animation-name: modalFrameHideTrans, modalFrameHideRot;
        animation-duration: 400ms, 500ms;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.47, -0.5, 1, 0.27), cubic-bezier(0.47, -1, 0.42, 1);
    }
}

.modal.is-open .modal-frame {
    transition-delay: 0ms;
    transition-duration: 200ms, 400ms;
    transition-timing-function: ease, cubic-bezier(0.24, 1.78, 0.6, 0.83);
    opacity: 1;
    transform: translateY(0px) rotate(0deg);
    animation: none;
}

@keyframes modalFrameHideTrans {
    0% {
        translate: 0 0;
    }
    100% {
        translate: 0 200px;
    }
}
@keyframes modalFrameHideRot {
    0% {
        rotate: 0;
    }
    50%,
    100% {
        rotate: 3deg;
    }
}

.modal-frame_large {
    width: 100%;
}

.modal-innerBody {
    padding: 16px;
}

.modal-header {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 16px 40px 16px 16px;
    font-size: 1.3em;
}

.modal-body {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}

.modal-scroll {
    flex-grow: 1;
    flex-shrink: 1;
}

.modal-footer {
    flex-grow: 0;
    flex-shrink: 0;
    display: grid;
    padding: 16px;
    gap: 16px;
    @media (min-width: 500px) {
        grid-template-columns: max-content 1fr max-content;
        align-items: end;
        & > :nth-last-child(1) {
            /* Prev button */
            grid-column: 1;
            grid-row: 1;
        }
        & > :nth-last-child(2) {
            /* Next button */
            grid-column: 3;
            grid-row: 1;
        }
    }
    &.modal-footer_ltr {
        & > :nth-last-child(1) {
            /* Next button */
            grid-column: 3;
        }
        & > :nth-last-child(2) {
            /* Prev button */
            grid-column: 1;
        }
    }
}

.modal-close {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 32px;
    height: 32px;
}

.modal-error {
    @media (min-width: 500px) {
        grid-column: 2;
        grid-row: 1;
        justify-self: end;
    }
}
