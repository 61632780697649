.buttonIcon {
    border-radius: var(--interactableRadius);
    padding: 6px;
    line-height: 0;
    display: inline-flex;
    gap: 8px;
    align-items: center;
}

.buttonIcon.button_pending {
    & > .spinner {
        height: calc(100% - 12px);
        margin: 6px;
        width: auto;
        aspect-ratio: 1;
        top: 0;
        left: 0;
    }
    & > svg:not(.spinner) {
        opacity: 0.2;
    }
}
