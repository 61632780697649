.inputGrid {
    --inputGrid-colWidth: 2;
}

.inputGrid-grid {
    --gap: 16px;

    display: grid;
    align-items: start;
    gap: var(--gap);
    grid-template-columns: repeat(
        auto-fill,
        minmax(
            max(
                var(--input-minWidth),
                calc((100% - (var(--gap) * (var(--inputGrid-colWidth) - 1))) / var(--inputGrid-colWidth))
            ),
            1fr
        )
    );

    /* Specific handling for grids that only contain labeled inputs to correctly align lables that wrap to multi line. */
    /* This selector is saying; only when all children are `.labeledInput`s */
    &:not(:has(> :not(.labeledInput))) {
        gap: 0 var(--gap);
        margin-bottom: calc(var(--gap) * -1);

        & > .labeledInput {
            grid-template-rows: subgrid;
            grid-row: span 2; /* This 2 is the amount of children of labeledInput */
            margin-bottom: var(--gap);

            & .labeledInput-label {
                align-self: flex-end;
            }
        }
    }
}

.inputGrid-label {
    display: block;
    font-weight: bold;
    font-size: 0.9em;
    padding-bottom: 16px;
}
