.layoutGrid {
    display: grid;
    gap: 16px;
}

.layoutGrid-item {
    width: 100%;
    & > * {
        height: 100%;
    }
}
