.dateInput {
}

.dateInput-button {
}

.dateInput-menu {
    padding: 8px 16px 16px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: start;
}

.dateInput-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}

.dateInput-presets {
    margin-left: 16px;
    padding: 0 16px;
    border-left: 1px solid var(--color-brand-1);
}
