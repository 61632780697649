html,
body,
[data-theme] {
    line-height: 1;
    font-family: var(--fontFamily-system);
    font-size: 14px;
    color: var(--color-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--color-bg);
    overscroll-behavior-x: none;
    overflow-x: hidden;
    /* This doesn't seem sensible */
    overflow-y: hidden;
}

p {
    line-height: 1.5;
}

#root {
    /* This fixes a weird scroll bug with page overflow on touch */
    position: relative;
}

/* General Utilities */
body,
#root {
    & .u-hidden {
        display: none !important;
    }

    & .u-invisible {
        position: absolute;
        left: -9999px;
        top: -9999px;
        opacity: 0.01;
    }

    & .u-noWrap {
        white-space: nowrap;
    }

    & .u-pageWidth {
        max-width: 1000px;
        margin: auto;
        width: 100%;
    }

    & .u-textLeft {
        text-align: left;
    }

    & .u-textRight {
        text-align: right;
    }

    & .u-textCenter {
        text-align: center;
    }

    & .u-block {
        display: block;
    }

    & .u-inlineBlock {
        display: inline-block;
    }

    & .u-inlineGrid {
        display: inline-grid;
    }

    & .u-relative {
        position: relative;
        z-index: 1;
    }

    & .u-absolute {
        position: absolute;
    }

    & .u-clear {
        &:after {
            content: "\00A0";
            clear: both;
            display: block;
            height: 0px;
        }
    }

    & .u-gridClear {
        grid-column: 1 / -1;
    }

    & .u-flatLink {
        text-decoration: inherit;
        font: inherit;
        color: inherit;
        padding: 0;
        margin: 0;
        display: inline;
    }

    & .u-flatButton {
        font: inherit;
        padding: 0;
        background: 0;
        border: 0;
        margin: 0;
        display: inline;
        color: inherit;
        text-align: inherit;
    }

    & .u-link {
        text-decoration: underline;
        color: var(--color-brand-4);
        cursor: pointer;
    }

    & .u-overflow {
        overflow: hidden;
    }

    & .u-hAlign {
        display: flex;
        align-items: center;
    }

    & .u-hAlignInline {
        display: inline-flex;
        align-items: center;
    }

    & .u-hSpacing {
        display: flex;
        justify-content: space-between;
    }

    & .u-inlineList {
        display: inline;
        counter-reset: inlineList;
        & > li {
            display: inline;
            counter-increment: inlineList;
            &:before {
                content: "(" counter(inlineList) ")";
                display: inline-block;
                padding: 0 0.5em;
            }
        }
    }

    & .u-fade {
        opacity: 0.7;
    }
    & .u-fade2 {
        opacity: 0.4;
    }
    & .u-vCenter {
        display: grid;
        height: 100%;
        align-items: center;
    }
    & .u-hCenter {
        display: grid;
        justify-items: center;
    }

    & .u-vSpacing {
        display: grid;
        gap: 8px;
    }

    & .u-2ColGrid {
        display: grid;
        gap: 16;
        grid-template-columns: repeat(2, auto);
    }

    & .u-fontSmall {
        font-size: 0.8rem;
    }
}

/* Formating for plain text */
.u-textContent {
    line-height: 1.5;
    letter-spacing: 1px;

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        margin: 0 0 32px;
        &:not(:first-child) {
            margin-top: 48px;
        }
    }
    & h1 + h2:not(:first-child),
    & h2 + h3:not(:first-child),
    & h3 + h4:not(:first-child),
    & h4 + h5:not(:first-child),
    & h5 + h6:not(:first-child) {
        margin-top: 0;
    }
    & h1 {
        font-size: 2.5em;
        font-weight: normal;
    }
    & h2 {
        font-size: 1.9em;
        font-weight: normal;
    }
    & h3 {
        font-size: 1.3em;
        font-weight: normal;
    }
    & h4 {
        font-size: 1.1em;
    }
    & h5 {
        font-size: 1em;
        text-transform: capitalise;
    }
    & h6 {
        font-size: 0.8em;
        text-transform: capitalise;
    }

    & p {
        margin: 0 0 32px;
    }

    & ul {
        list-style: disc;
        margin: 0 0 32px 24px;
        & li {
            margin: 0 0 8px;
        }
    }

    & ol {
        list-style: decimal;
        margin: 0 0 32px 24px;
        & li {
            margin: 0 0 8px;
        }
    }

    & li > ol,
    & li > ul {
        margin-top: 16px;
    }

    & dl {
        margin: 0 0 32px;
        & dt {
            display: block;
            margin: 0 0 8px;
            font-weight: bold;
        }
        & dd {
            display: block;
            margin: 0 0 16px;
        }
        @media (min-width: 600px) {
            display: grid;
            grid-template-columns: min-content 1fr;
            grid-gap: 16px;
            & dt,
            & dd {
                margin: 0;
            }
        }
    }

    & address {
        margin: 0 0 32px 16px;
        font-style: normal;
    }

    & time {
        margin: 0 0 32px;
    }

    & img {
        max-width: 100%;
    }

    & table {
        width: 100%;
        margin-bottom: 32px;
    }
    & th,
    & td {
        border: 1px solid hsla(var(--baseColor-text), 0.2);
        padding: 8px;
    }
    & th {
        text-align: left;
        font-weight: 600;
    }
    & tr:nth-child(even) {
        background: hsla(var(--baseColor-text), 0.1);
    }
    & thead tr:nth-child(1n + 1) {
        background: hsla(var(--baseColor-text), 0.1);
        color: var(--color-text);
        & td,
        & th {
            border-bottom: 1px solid var(--color-brand-1);
        }
    }
}

/* Atomic padding and margins */
body,
#root {
    & .u-p0 {
        padding: 0px;
    }
    & .u-m0 {
        margin: 0px;
    }
    & .u-p1 {
        padding: 1px;
    }
    & .u-m1 {
        margin: 1px;
    }
    & .u-p2 {
        padding: 2px;
    }
    & .u-m2 {
        margin: 2px;
    }
    & .u-p4 {
        padding: 4px;
    }
    & .u-m4 {
        margin: 4px;
    }
    & .u-p8 {
        padding: 8px;
    }
    & .u-m8 {
        margin: 8px;
    }
    & .u-p12 {
        padding: 12px;
    }
    & .u-m12 {
        margin: 12px;
    }
    & .u-p16 {
        padding: 16px;
    }
    & .u-m16 {
        margin: 16px;
    }
    & .u-p24 {
        padding: 24px;
    }
    & .u-m24 {
        margin: 24px;
    }
    & .u-p32 {
        padding: 32px;
    }
    & .u-m32 {
        margin: 32px;
    }
    & .u-p48 {
        padding: 48px;
    }
    & .u-m48 {
        margin: 48px;
    }
    & .u-p64 {
        padding: 64px;
    }
    & .u-m64 {
        margin: 64px;
    }

    & .u-pt0 {
        padding-top: 0px;
    }
    & .u-mt0 {
        margin-top: 0px;
    }
    & .u-pr0 {
        padding-right: 0px;
    }
    & .u-mr0 {
        margin-right: 0px;
    }
    & .u-pb0 {
        padding-bottom: 0px;
    }
    & .u-mb0 {
        margin-bottom: 0px;
    }
    & .u-pl0 {
        padding-left: 0px;
    }
    & .u-ml0 {
        margin-left: 0px;
    }
    & .u-mv0 {
        margin-block: 0px;
    }
    & .u-pv0 {
        padding-block: 0px;
    }
    & .u-mh0 {
        margin-inline: 0px;
    }
    & .u-ph0 {
        padding-inline: 0px;
    }

    & .u-pt1 {
        padding-top: 1px;
    }
    & .u-mt1 {
        margin-top: 1px;
    }
    & .u-pr1 {
        padding-right: 1px;
    }
    & .u-mr1 {
        margin-right: 1px;
    }
    & .u-pb1 {
        padding-bottom: 1px;
    }
    & .u-mb1 {
        margin-bottom: 1px;
    }
    & .u-pl1 {
        padding-left: 1px;
    }
    & .u-ml1 {
        margin-left: 1px;
    }
    & .u-mv1 {
        margin-block: 1px;
    }
    & .u-pv1 {
        padding-block: 1px;
    }
    & .u-mh1 {
        margin-inline: 1px;
    }
    & .u-ph1 {
        padding-inline: 1px;
    }

    & .u-pt2 {
        padding-top: 2px;
    }
    & .u-mt2 {
        margin-top: 2px;
    }
    & .u-pr2 {
        padding-right: 2px;
    }
    & .u-mr2 {
        margin-right: 2px;
    }
    & .u-pb2 {
        padding-bottom: 2px;
    }
    & .u-mb2 {
        margin-bottom: 2px;
    }
    & .u-pl2 {
        padding-left: 2px;
    }
    & .u-ml2 {
        margin-left: 2px;
    }
    & .u-mv2 {
        margin-block: 2px;
    }
    & .u-pv2 {
        padding-block: 2px;
    }
    & .u-mh2 {
        margin-inline: 2px;
    }
    & .u-ph2 {
        padding-inline: 2px;
    }

    & .u-pt4 {
        padding-top: 4px;
    }
    & .u-mt4 {
        margin-top: 4px;
    }
    & .u-pr4 {
        padding-right: 4px;
    }
    & .u-mr4 {
        margin-right: 4px;
    }
    & .u-pb4 {
        padding-bottom: 4px;
    }
    & .u-mb4 {
        margin-bottom: 4px;
    }
    & .u-pl4 {
        padding-left: 4px;
    }
    & .u-ml4 {
        margin-left: 4px;
    }
    & .u-mv4 {
        margin-block: 4px;
    }
    & .u-pv4 {
        padding-block: 4px;
    }
    & .u-mh4 {
        margin-inline: 4px;
    }
    & .u-ph4 {
        padding-inline: 4px;
    }

    & .u-pt8 {
        padding-top: 8px;
    }
    & .u-mt8 {
        margin-top: 8px;
    }
    & .u-pr8 {
        padding-right: 8px;
    }
    & .u-mr8 {
        margin-right: 8px;
    }
    & .u-pb8 {
        padding-bottom: 8px;
    }
    & .u-mb8 {
        margin-bottom: 8px;
    }
    & .u-pl8 {
        padding-left: 8px;
    }
    & .u-ml8 {
        margin-left: 8px;
    }
    & .u-mv8 {
        margin-block: 8px;
    }
    & .u-pv8 {
        padding-block: 8px;
    }
    & .u-mh8 {
        margin-inline: 8px;
    }
    & .u-ph8 {
        padding-inline: 8px;
    }

    & .u-pt12 {
        padding-top: 12px;
    }
    & .u-mt12 {
        margin-top: 12px;
    }
    & .u-pr12 {
        padding-right: 12px;
    }
    & .u-mr12 {
        margin-right: 12px;
    }
    & .u-pb12 {
        padding-bottom: 12px;
    }
    & .u-mb12 {
        margin-bottom: 12px;
    }
    & .u-pl12 {
        padding-left: 12px;
    }
    & .u-ml12 {
        margin-left: 12px;
    }
    & .u-mv12 {
        margin-block: 12px;
    }
    & .u-pv12 {
        padding-block: 12px;
    }
    & .u-mh12 {
        margin-inline: 12px;
    }
    & .u-ph12 {
        padding-inline: 12px;
    }

    & .u-pt16 {
        padding-top: 16px;
    }
    & .u-mt16 {
        margin-top: 16px;
    }
    & .u-pr16 {
        padding-right: 16px;
    }
    & .u-mr16 {
        margin-right: 16px;
    }
    & .u-pb16 {
        padding-bottom: 16px;
    }
    & .u-mb16 {
        margin-bottom: 16px;
    }
    & .u-pl16 {
        padding-left: 16px;
    }
    & .u-ml16 {
        margin-left: 16px;
    }
    & .u-mv16 {
        margin-block: 16px;
    }
    & .u-pv16 {
        padding-block: 16px;
    }
    & .u-mh16 {
        margin-inline: 16px;
    }
    & .u-ph16 {
        padding-inline: 16px;
    }

    & .u-pt24 {
        padding-top: 24px;
    }
    & .u-mt24 {
        margin-top: 24px;
    }
    & .u-pr24 {
        padding-right: 24px;
    }
    & .u-mr24 {
        margin-right: 24px;
    }
    & .u-pb24 {
        padding-bottom: 24px;
    }
    & .u-mb24 {
        margin-bottom: 24px;
    }
    & .u-pl24 {
        padding-left: 24px;
    }
    & .u-ml24 {
        margin-left: 24px;
    }
    & .u-mv24 {
        margin-block: 24px;
    }
    & .u-pv24 {
        padding-block: 24px;
    }
    & .u-mh24 {
        margin-inline: 24px;
    }
    & .u-ph24 {
        padding-inline: 24px;
    }

    & .u-pt32 {
        padding-top: 32px;
    }
    & .u-mt32 {
        margin-top: 32px;
    }
    & .u-pr32 {
        padding-right: 32px;
    }
    & .u-mr32 {
        margin-right: 32px;
    }
    & .u-pb32 {
        padding-bottom: 32px;
    }
    & .u-mb32 {
        margin-bottom: 32px;
    }
    & .u-pl32 {
        padding-left: 32px;
    }
    & .u-ml32 {
        margin-left: 32px;
    }
    & .u-mv32 {
        margin-block: 32px;
    }
    & .u-pv32 {
        padding-block: 32px;
    }
    & .u-mh32 {
        margin-inline: 32px;
    }
    & .u-ph32 {
        padding-inline: 32px;
    }

    & .u-pt48 {
        padding-top: 48px;
    }
    & .u-mt48 {
        margin-top: 48px;
    }
    & .u-pr48 {
        padding-right: 48px;
    }
    & .u-mr48 {
        margin-right: 48px;
    }
    & .u-pb48 {
        padding-bottom: 48px;
    }
    & .u-mb48 {
        margin-bottom: 48px;
    }
    & .u-pl48 {
        padding-left: 48px;
    }
    & .u-ml48 {
        margin-left: 48px;
    }
    & .u-mv48 {
        margin-block: 48px;
    }
    & .u-pv48 {
        padding-block: 48px;
    }
    & .u-mh48 {
        margin-inline: 48px;
    }
    & .u-ph48 {
        padding-inline: 48px;
    }

    & .u-pt64 {
        padding-top: 64px;
    }
    & .u-mt64 {
        margin-top: 64px;
    }
    & .u-pr64 {
        padding-right: 64px;
    }
    & .u-mr64 {
        margin-right: 64px;
    }
    & .u-pb64 {
        padding-bottom: 64px;
    }
    & .u-mb64 {
        margin-bottom: 64px;
    }
    & .u-pl64 {
        padding-left: 64px;
    }
    & .u-ml64 {
        margin-left: 64px;
    }
    & .u-mv64 {
        margin-block: 64px;
    }
    & .u-pv64 {
        padding-block: 64px;
    }
    & .u-mh64 {
        margin-inline: 64px;
    }
    & .u-ph64 {
        padding-inline: 64px;
    }
}
