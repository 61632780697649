.flag {
    padding: 8px;
    border: 1px solid var(--color-text);
    display: inline-block;
    border-radius: 999px;
    background-color: var(--color-text);
    background-size: 100% 100%;
    background-position: center center;
    position: relative;
    overflow: hidden;
}

.flag:not(.flag_faded):not(.flag_small) {
    box-shadow: -3px -3px 6px 1px rgba(0, 0, 0, 0.2) inset;
    &:before {
        content: "";
        position: absolute;
        inset: 0;
        background: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0) 70%);
    }
}

.flag_faded {
    box-shadow: -3px -3px 6px 1px hsla(0, 0%, 100%, 0.15) inset;
    filter: saturate(0.3);
    &:after {
        content: "";
        position: absolute;
        inset: 0;
        background: hsla(0, 0%, 100%, 0.4);
    }
}

.flag_medium {
    padding: 16px;
    border: 2px solid var(--color-text);
}

.flag_large {
    padding: 32px;
    border: 3px solid var(--color-text);
}

.flag_dynamic {
    border: 2px solid var(--color-text);
}
