.select {
    position: relative;
    padding-right: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}
