.errorScreen {
    padding: 64px 16px;
    @media (min-height: 1000px) {
        padding: 128px 16px;
    }

    & h1 {
        font-size: 2.5em;
        margin-bottom: 64px;
    }
    & p {
        margin-bottom: 16px;
    }
}

#root > .errorScreen {
    min-height: 100vh;
}

.errorScreen-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 1000px;
    margin: auto;
    width: 100%;
}

.errorScreen-icon {
    color: var(--color-danger);
    margin-bottom: 32px;
}

.errorScreen-stack {
    text-align: left;
    overflow-x: auto;
}
