.landingPage {
    display: grid;
    width: 100%;
    min-height: 100%;
    grid-template-columns: 1fr 0.65fr;
    grid-template-rows: 0.8fr 1fr;
    grid-template-areas:
        "welcome login"
        "quote quote";
    @media (max-width: 1080px) {
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
            "welcome"
            "login"
            "quote";
    }
}

.landingPage-title {
    font-size: 2.5em;
}

.landingPage-welcome {
    grid-area: welcome;
    display: grid;
    place-items: center;
    padding: 32px;
    background: linear-gradient(155deg, var(--gradient-bar));
    @media (max-width: 1080px) {
        min-height: 100vh;
    }
}

.landingPage-login {
    grid-area: login;
    display: grid;
    align-items: center;
    background: var(--color-bg);
    padding: 5vw;
}

.landingPage-quote {
    grid-area: quote;
    display: grid;
    place-items: center;
    padding: 32px;
    color: var(--color-text-inv);
    background: var(--color-neutral-100);
    @media (max-width: 1080px) {
        min-height: 100vh;
    }
}

.landingPage-box {
    padding: 32px 128px;
    @media (max-width: 1200px) {
        padding: 16px;
    }
}

.landingPage-video {
    height: 25vh;
    min-height: 250px;
    max-height: 350px;
    aspect-ratio: 16/9;
    margin: auto;
    margin-bottom: 32px;
    @media (max-width: 1080px) {
        width: 80vw;
        height: auto;
        min-height: 0;
        max-height: none;
    }
}

.landingPage-textWrap {
    max-width: 1000px;
}

/* This is a little hacky, and has an issue with hidden padding.
But works well enough to cover an edge case with responsiveness. */
.landingPage\~scroller > .scroller-view > .scroller-child {
    height: 100%;
}
