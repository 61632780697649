.toolMenu {
    --borderColor: var(--color-brand-1);
    --backgroundColor: var(--color-bg);
    --transition-func: cubic-bezier(0.35, 1.84, 0.5, 0.84);

    color: var(--color-text);
    position: absolute;
    top: 0;
    left: 0;
    max-width: 95vw;
    border-radius: 8px;
    z-index: 10;
    filter: drop-shadow(0 8px 6px rgba(0, 0, 0, 0.4));

    opacity: 0;
    transition: ease 200ms, var(--transition-func) 0ms, var(--transition-func) 0ms;
    transition-property: opacity, top, left;
    transition-delay: 100ms, 0ms, 0ms;
    pointer-events: none;
    &.is-open {
        opacity: 1;
        pointer-events: initial;
        transition-delay: 0ms, 0ms, 0ms;
        transition-duration: 200ms, 200ms, 200ms;
        & .toolMenu-content {
            transform: translate(0, 0);
            opacity: 1;
        }
    }
    & > svg {
        --insetX: 0;
        --insetY: 0;
        z-index: -1;
        pointer-events: none;
        position: absolute;
        inset: var(--insetY) var(--insetX);
    }
    & > svg path {
        transition: d 200ms var(--transition-func);
    }
    /* This selector is written with the not for better progressive enanchment */
    &:not(.is-open) > svg path {
        d: var(--closedPath);
        transition-timing-function: ease-out;
    }
}

.toolMenu-target {
    /* This is !important because it must override any transitions the cloned element came with */
    transition: var(--transition-func) 200ms !important;
    transition-property: top, left;
}

.toolMenu-content {
    transition: transform ease 300ms;
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.28, 2.01, 0.62, 0.78);
    opacity: 0;
}

.toolMenu_up {
    &:not(.is-open) .toolMenu-content {
        transform: translate(0, 24px);
    }
}

.toolMenu_down {
    &:not(.is-open) .toolMenu-content {
        transform: translate(0, -24px);
    }
    & > svg {
        top: auto;
    }
}

.toolMenu_right {
    &:not(.is-open) .toolMenu-content {
        transform: translate(-24px, 0);
    }
    & > svg {
        left: auto;
    }
}

.toolMenu_left {
    &:not(.is-open) .toolMenu-content {
        transform: translate(24px, 0);
    }
}
