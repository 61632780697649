.pageHeader {
}

.pageHeader-titleBlock {
    padding: 32px 0 48px;
    align-self: flex-start;
    & > h1 {
        font-size: 2.5em;
        margin-bottom: 8px;
    }
}

.pageHeader-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.pageHeader-icons {
    display: flex;
    gap: 8px;
}

.pageHeader-mid {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.pageHeader-filters {
    padding: 16px 0 32px;
}
