.downtime {
    display: grid;
    min-height: 100lvh;
    padding: 16px;
    place-items: center;
}

.downtime-title {
    font-weight: bold;
    font-size: 1.2rem;
}

.downtime-card {
    max-width: 600px;
}
