.actionPage {
    padding: 32px;
    max-width: 600px;
    width: 100%;
    margin: auto;
}

.actionPage-title {
    margin-bottom: 32px;
    font-size: 1.6em;
}

.actionPage-card {
    padding: 16px;
    @media (min-width: 600px) {
        padding: 32px;
    }
}
