.navigation {
    --nav-width: 250px;
    --nav-leftPadding: 16px;

    position: relative;
    background: var(--color-fg);
    z-index: 2;
    font-size: 0.9em;
    width: var(--nav-width);

    @media (--floatingNav) {
        position: fixed;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 100%;
        transition: transform 300ms ease-in;
        box-shadow: -100px 0 0 var(--color-fg), 2px 0 4px rgba(0, 0, 0, 0.6);
        transform: translateX(-10%);
        &.is-open {
            transform: translateX(100%);
            transition-duration: 500ms;
            transition-timing-function: cubic-bezier(0.28, 1.48, 0.52, 1);
        }
    }
}

.navigation-menu {
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding-bottom: 32px;
}

.navigation-bg {
    position: fixed;
    inset: 0;
    background: hsla(var(--baseColor-bg), 0.7);
    pointer-events: none;
    opacity: 0;
    transition: ease 200ms;
    z-index: 2;
    &.is-open {
        pointer-events: initial;
        opacity: 1;
    }
    & > button {
        position: absolute;
        top: 8px;
        right: 8px;
    }
    @media not all and (--floatingNav) {
        display: none;
    }
}

.navigation-logo {
    /* NOTE: This is actually where the width of the nav gets set */
    padding: 24px 0 12px;
    text-decoration: none;
    align-self: center;
}

.navigation-heading {
    margin: 0 32px 8px var(--nav-leftPadding);
    padding-bottom: 4px;
    border-bottom: 1px solid currentColor;
    font-size: 0.9em;
}

.navigation-link {
    text-decoration: none;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px;
    padding-left: var(--nav-leftPadding);
    position: relative;
    transition: color 200ms ease;

    &:before {
        content: "";
        position: absolute;
        inset: 0 auto 0 0;
        width: 4px;
        border-radius: 0 4px 4px 0;
        background: var(--color-brand-4);
        transform: translateX(-6px);
        opacity: 0;
        transition-property: opacity, transform;
        transition-duration: 0ms, 400ms;
        transition-delay: 400ms, 0ms;
        transition-timing-function: ease;
    }

    &.is-active {
        color: var(--color-brand-4);
        &:before {
            transform: translateX(0px);
            opacity: 1;
            transition-duration: 0ms, 400ms;
            transition-delay: 0ms, 0ms;
        }
    }
}

.navigation-group + .navigation-group {
    margin-top: 32px;
}

.navigation-button.navigation-button {
    @media not all and (--floatingNav) {
        display: none;
    }
}

.navigation\~root {
    display: grid;
    grid-template-columns: auto 1fr;
    @media (--floatingNav) {
        grid-template-columns: 1fr;
    }
}

.navigation-page {
    position: relative;
}

.navigation-pageInner {
    position: absolute;
    inset: 0;
}

.navigation-spacer {
    padding: 0 0 32px;
    @media not all and (--floatingNav) {
        padding: 0 16px 32px;
    }
}
