/* Date Picker plugin style overrides */
body {
    /* Root element */
    & .react-calendar {
        border: none;
        background: none;
        color: var(--color-text);
        font-size: 0.9em;
    }

    /* Buttons */
    & .react-calendar__tile {
        border: 1px solid transparent;
        color: inherit;
        font-size: inherit;
        &:enabled:hover {
            background: none;
        }
        &:enabled:focus {
            background: none;
        }
        &:enabled:focus-visible {
            background: none;
            outline: 1px solid var(--color-brand-2);
            outline-offset: -3px;
        }
    }
    & .react-calendar__month-view__days__day--weekend {
    }
    & .react-calendar__month-view__days__day--neighboringMonth {
        color: hsla(var(--baseColor-text), 0.5);
    }
    & .react-calendar__tile--now {
        background: none;
        text-decoration: underline;
        text-decoration-color: var(--color-brand-2);
    }
    & .react-calendar__tile--now.react-calendar__tile--active {
        background: var(--color-brand-3);
    }

    /* Ranges */
    & .react-calendar__tile--hasActive,
    & .react-calendar__tile--hasActive:enabled:hover,
    & .react-calendar__tile--hasActive:enabled:focus,
    & .react-calendar__tile--active,
    & .react-calendar__tile--active:enabled:hover,
    & .react-calendar__tile--active:enabled:focus {
        background: var(--color-brand-3);
    }
    & .react-calendar__tile:disabled,
    & .react-calendar__tile:disabled:hover,
    & .react-calendar__tile:disabled:focus {
        background-color: transparent;
        color: hsla(var(--baseColor-text), 0.5);
    }
    & .react-calendar__tile--rangeStart {
        border-left-color: var(--color-brand-2);
    }
    & .react-calendar__tile--rangeEnd {
        border-right-color: var(--color-brand-2);
    }
    & .react-calendar--selectRange .react-calendar__tile--active,
    & .react-calendar--selectRange .react-calendar__tile--active:focus,
    & .react-calendar--selectRange .react-calendar__tile--hover:hover,
    & .react-calendar--selectRange .react-calendar__tile--hover {
        background: var(--color-brand-2);
    }
    & .react-calendar--selectRange .react-calendar__tile {
        border-color: transparent;
    }

    /* Month nav buttons */
    & .react-calendar__navigation {
        margin-bottom: 0;
    }
    & .react-calendar__navigation__label,
    & .react-calendar__navigation__arrow {
        color: inherit;
        font-size: inherit;
        &[disabled] {
            background: none;
        }
        &:enabled:hover {
            background: none;
        }
        &:enabled:focus {
            background: none;
        }
        &:enabled:focus-visible {
            background: none;
            outline: 1px solid var(--color-brand-2);
            outline-offset: -2px;
        }
    }

    /* Day headings */
    & .react-calendar__month-view__weekdays abbr {
        border: none;
        text-decoration: none;
        text-transform: none;
    }
}
