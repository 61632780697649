.weekHourInput\~root {
    container: weekHourInput / inline-size;
}

.weekHourInput {
    display: grid;
    grid-template-columns: auto repeat(7, 1fr);
    grid-auto-flow: column dense;
    gap: 4px;
    text-align: center;
    justify-items: center;
    &:before {
        /* Taking up the top left corner */
        content: "";
        grid-column: 1;
        grid-row: 1;
    }
    &.is-invalid {
        outline: 2px solid var(--color-danger);
        outline-offset: 2px;
        border-radius: var(--interactableRadius);
    }
    &.is-disabled {
        --color-text: var(--color-neutral-600);
        --color-brand-1: var(--color-neutral-600);
        color: var(--color-text);
    }
    &.weekHourInput_inline {
        display: inline-grid;
    }
}

.weekHourInput-hour {
    grid-column: 1;
}

.weekHourInput-day {
    grid-row: 1;
}

.weekHourInput-label {
    display: flex;
    gap: 8px;
    justify-self: center;
}

.weekHourInput-label_v {
    flex-direction: column;
    margin-bottom: 4px;
}
.weekHourInput-label_h {
    margin-right: 4px;
    justify-self: end;
}

.weekHourInput-boxWrap {
    margin: auto;
    width: 20px;
    height: 20px;
    position: relative;
    /* Expanding the hit area to aid in clicking */
    &:after {
        content: "";
        position: absolute;
        inset: -4px;
    }
}

.weekHourInput-box {
    padding: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    background: var(--color-text);
    border-radius: 99px;
    opacity: 0.4;
    transition: ease 200ms;
    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 16px;
        background: var(--color-brand-1);
        transition: ease 300ms;
        z-index: -1;
        opacity: 0;
        border-radius: 99px;
        transform-origin: bottom center;
        pointer-events: none;
    }
    &:after {
        top: 0;
        bottom: auto;
        transform-origin: top center;
    }
    &.is-checked {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        background: var(--color-brand-1);
        &:before,
        &:after {
            opacity: 1;
        }
        &.is-globBefore:before,
        &.is-globAfter:after {
            height: 40px;
        }
    }
    &.is-globBefore,
    &.is-globAfter {
        transition: 50ms;
        &.is-checked {
            transition-delay: 300ms;
        }
    }
}

.weekHourInput-input {
    opacity: 0.01;
    z-index: 1;
    position: relative;
}

body:not(.is-mouse) .weekHourInput-boxWrap:focus-within {
    border-radius: 99px;
    outline: 1px solid var(--color-text);
}

/* This doesn't work in all browsers, but we don't have any implimentations in the UI where it's needed */
@container weekHourInput (min-width: 1100px) {
    .weekHourInput {
        grid-template-columns: auto repeat(24, 40px);
        grid-auto-flow: row dense;
        display: inline-grid;
    }
    .weekHourInput-label_v {
        flex-direction: row;
        margin-bottom: 0;
        margin-right: 4px;
        justify-self: end;
    }
    .weekHourInput-label_h {
        flex-direction: column;
        margin-right: 0;
        margin-bottom: 4px;
        justify-self: center;
    }
    .weekHourInput-hour {
        grid-column: revert;
        grid-row: 1;
    }
    .weekHourInput-day {
        grid-column: 1;
        grid-row: revert;
    }
    .weekHourInput-boxWrap {
        transform: rotate(-90deg);
        &:after {
            height: 40px;
            top: -10px;
        }
    }
    .weekHourInput-box.is-checked {
        &.is-globBefore:before,
        &.is-globAfter:after {
            height: 60px;
        }
    }
}
