.app {
    display: grid;
    min-height: 100vh;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    position: relative;
}

.app-page {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    & > * {
        flex-grow: 1;
    }
}
