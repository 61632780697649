.statRadial {
    display: inline-block;
    text-align: center;
    max-width: calc(var(--statSize, 0) * 1px);
}

.statRadial-barOuter {
    position: relative;
    font-size: clamp(0.6rem, calc(var(--statSize, 180) * 0.005em), 4rem);
}

.statRadial-value {
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    left: 15%;
    width: 70%;
}

.statRadial-valuePer {
    font-size: 2em;
    font-weight: bold;
    padding-bottom: 8px;
    color: var(--barColor, inherit);
}

.statRadial-valueAbs {
    font-size: 1.2em;
    color: hsla(var(--baseColor-text), 0.7);
    line-height: 1.4em;
}

.statRadial-label {
    font-weight: bold;
    padding-top: 8px;
}

.statRadial-subLabel {
    padding-top: 8px;
    font-size: 0.9em;
    color: hsla(var(--baseColor-text), 0.7);
}
