.campaignAsidePanel {
    display: grid;
    gap: 8px 16px;
    grid-template:
        "name name" 1fr
        "cta dials" auto / 1fr 1fr;
    height: 100%;
    padding-left: 16px;
    overflow: hidden;
    @media (--asidePanelRight) {
        overflow: initial;
        padding: 0;
        gap: 48px;
        align-items: center;
        text-align: center;
        grid-template:
            "name" auto
            "dials" auto
            "cta" 1fr / 1fr;
    }
}

.campaignAsidePanel-name {
    grid-area: name;
    display: grid;
    gap: 0 16px;
    grid-template-columns: max-content 1fr;
    grid-template-rows: min-content 1fr;
    white-space: nowrap;
    @media (--asidePanelRight) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
}

.campaignAsidePanel-icon {
    grid-row: span 2;
    @media (--asidePanelRight) {
        margin-bottom: 8px;
    }
    & img {
        border: currentColor 2px solid;
    }
}

.campaignAsidePanel-title {
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 4px;
}

.campaignAsidePanel-subTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 4px;
}

.campaignAsidePanel-cta {
    grid-area: cta;
    display: grid;
    gap: 16px;
    align-items: center;
    align-self: center;
    @media (--asidePanelRight) {
        grid-template-columns: 1fr;
        align-self: flex-end;
    }
}

.campaignAsidePanel-dials {
    grid-area: dials;
    display: grid;
    place-items: center;
    padding: 0 16px 4px;
    @media (--asidePanelRight) {
        padding: 0;
        display: grid;
        gap: 32px;
    }
}

.campaignAsidePanel.is-collapsed {
    @media not all and (--asidePanelRight) {
        grid-template: "name" 1fr / 1fr;
    }
    & .campaignAsidePanel-dials {
        display: none;
    }
    & .campaignAsidePanel-cta {
        display: none;
    }
    & .campaignAsidePanel-subTitle {
        @media (--asidePanelRight) {
            display: none;
        }
    }
    & .campaignAsidePanel-title {
        @media (--asidePanelRight) {
            writing-mode: vertical-rl;
            text-orientation: mixed;
            display: flex;
            align-items: center;
        }
    }
}
