.successMessage {
    /* This is a little bit of a hack to change the color of the underlying InfoBox */
    --color-brand-2: var(--color-success);
    padding-left: 36px;
    position: relative;
}

.successMessage-icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    fill: var(--color-success);
}
