@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");

.logo {
    display: inline-grid;
    grid-template-columns: auto auto;
    gap: 12px;
    align-items: end;
    font-family: "Julius Sans One", var(--fontFamily-system);
    font-size: 1.4em;
    text-transform: uppercase;
}

.logo_portrait {
    grid-template-columns: 1fr;
    justify-items: center;
}
