.appIcon {
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
    background: var(--color-neutral-600);
    object-fit: cover;
    object-position: center center;
    /* iOS border radius ratio = 6.4 */
    border-radius: calc((var(--appIcon-size, 32px) / 6.4) * 1px);
}
