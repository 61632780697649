.notifications-alert svg {
    animation: notificationAlert;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-duration: 4s;
}

@keyframes notificationAlert {
    0% {
        transform: rotate(0deg);
    }
    2% {
        transform: rotate(20deg);
    }
    6% {
        transform: rotate(-15deg);
    }
    10% {
        transform: rotate(10deg);
    }
    14% {
        transform: rotate(-5deg);
    }
    16% {
        transform: rotate(0deg);
    }
}

.notifications-menu {
    padding: 8px;
    width: clamp(250px, 80vw, 450px);
}

.notifications-list {
    padding-right: 16px;
}

.notifications-note {
    position: relative;
    overflow: hidden;
    background: var(--color-brand-3);
    padding: 16px;
    padding-right: 32px;
    margin-bottom: 8px;
    border-left: 1px solid var(--color-brand-2);
    line-height: 1.2;
}

.notifications-header {
    display: flex;
    justify-content: space-between;
    padding: 4px 16px 12px 0;
}

.notifications-noteClose {
    position: absolute;
    top: 0;
    right: 0;
}

.notifications-title {
    font-weight: bold;
    font-size: 0.8em;
}
