.asidePanel\~root {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    align-items: stretech;
    overflow: hidden;
    @media (--asidePanelRight) {
        gap: 16px;
        flex-direction: row;
    }
}

.asidePanel {
    --expandedSize: 250px;
    --closedSize: 80px;
    --sizeDiff: calc(var(--expandedSize) - var(--closedSize));
    position: relative;
    background: var(--color-fg);
    @media not all and (--asidePanelRight), (--floatingNav) {
        /* Panel is touching nav */
        box-shadow: 10px 0 6px -10px rgba(0, 0, 0, 0.6) inset;
    }
    transition: transform 300ms cubic-bezier(0.35, 1.4, 0.5, 0.95);
    height: var(--expandedSize);
    transform: translateY(var(--sizeDiff));
    margin-top: calc(var(--sizeDiff) * -1);
    &.is-expanded {
        transform: translateY(0px);
        margin-top: 0;
    }
    @media (--asidePanelRight) {
        height: 100%;
        width: var(--expandedSize);
        transform: translateX(var(--sizeDiff));
        margin-top: 0;
        margin-left: calc(var(--sizeDiff) * -1);
        &.is-expanded {
            transform: translateX(0px);
            margin-top: 0;
            margin-left: 0;
        }
    }
}

.asidePanel-page {
    flex-grow: 1;
}

.asidePanel-expand {
    border: 0;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: var(--color-fg);
    padding: 0 16px;
    margin-bottom: -6px;
    border-radius: var(--card-radius) var(--card-radius) 0 0;
    & svg {
        transition: 600ms cubic-bezier(0.35, 1.84, 0.5, 0.84);
        transform: rotate(-90deg);
    }
    @media (--asidePanelRight) {
        bottom: auto;
        top: 64px;
        left: auto;
        right: 100%;
        transform: translateY(-50%);
        padding: 16px 0;
        margin-bottom: 0;
        margin-right: -6px;
        border-radius: var(--card-radius) 0 0 var(--card-radius);
        & svg {
            transform: rotate(180deg);
        }
    }
}

.asidePanel.is-expanded .asidePanel-expand {
    & svg {
        transform: rotate(90deg);
        @media (--asidePanelRight) {
            transform: rotate(0deg);
        }
    }
}

.asidePanel-element {
    height: var(--closedSize);
    @media (--asidePanelRight) {
        height: 100%;
        width: var(--closedSize);
    }
}
.asidePanel.is-expanded .asidePanel-element {
    height: var(--expandedSize);
    @media (--asidePanelRight) {
        height: 100%;
        width: var(--expandedSize);
    }
}

.asidePanel-elementWrap {
    padding: 16px;
    min-height: 100%;
    display: grid;
}
