.currencyInput {
    position: relative;
    color: var(--color-text);
    &.is-disabled {
        color: hsla(var(--baseColor-text), 0.5);
    }
}

.currencyInput-symbol {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none;
}

.currencyInput-input {
    padding-left: 32px;
}

.currencyInput-overlay {
    position: absolute;
    inset: 8px 8px 8px 32px;
    background: var(--input-background-color, var(--color-bg));
    pointer-events: none;
    z-index: 1;
    line-height: 1.4;
    border: 1px solid transparent;
}

.currencyInput-input:focus + .currencyInput-overlay {
    display: none;
}
