.checkbox {
    position: relative;
    display: inline-block;
}

.checkbox-el {
    position: relative;
    border: 1px solid var(--color-brand-1);
    width: 24px;
    height: 24px;
    background: var(--color-bg);
    display: inline-block;
    vertical-align: top;
    border-radius: var(--interactableRadius);
    outline: 0;
    transition: 100ms ease;
    &:after {
        content: "";
        height: 0;
        width: 0;
        border: 2px solid transparent;
        border-width: 0 0 2px 2px;
        position: absolute;
        top: 12px; /* Magic number! - There was some math that got these numbers from exact pixel amounts, so safe to hard code */
        left: 4px; /* Magic number! */
        transform-origin: top left;
        transform: translateY(50%) rotate(-45deg);
        opacity: 0;
        transition: height 1ms ease 300ms, width 1ms ease 300ms, border-color 0ms ease 300ms, transform 300ms ease 0ms,
            opacity 300ms ease 0ms;
    }
}

.is-checked > .checkbox-el {
    &:after {
        height: 6px;
        width: 12px;
        border-color: var(--color-text);
        transform: translateY(0) rotate(-45deg);
        opacity: 1;
        transition: height 120ms ease 0ms, width 200ms cubic-bezier(0.28, 2.01, 0.62, 0.78) 150ms,
            border-color 0ms ease 0ms, transform 0ms ease 0ms, opacity 0ms ease 0ms;
    }
}

input:focus + .checkbox-el {
    border-color: var(--color-brand-2);
}

.is-invalid > .checkbox-el {
    border-color: var(--color-danger);
    outline: 2px solid var(--color-danger);
    outline-offset: 2px;
}

.is-disabled > .checkbox-el {
    background: var(--color-neutral-800);
    border-color: var(--color-neutral-900);
    color: hsla(var(--baseColor-text), 0.5);
}
.is-disabled.is-checked > .checkbox-el {
    &:after {
        border-color: hsla(var(--baseColor-text), 0.5);
    }
}

.checkbox-input {
    position: absolute;
    top: 0;
    right: 200vw;
    width: 1px;
    height: 1px;
    opacity: 0.1;
}
