.card {
    box-shadow: var(--card-shadow);
    background: var(--color-fg);
    background-image: var(--card-shine);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    grid-auto-flow: dense;
    overflow: hidden;
    border-radius: var(--card-radius);
    position: relative;
    &:before {
        content: "";
        pointer-events: none;
        position: absolute;
        inset: 0;
        opacity: 0;
        transition: 800ms ease;
        background-image: radial-gradient(ellipse at 50% 100%, transparent 20%, transparent);
    }
}

.card_error:before {
    opacity: 1;
    background-image: radial-gradient(ellipse at 50% 100%, hsla(0, 90%, 30%, 0.6) 20%, transparent);
}

.card_success:before {
    opacity: 1;
    background-image: radial-gradient(ellipse at 50% 100%, hsla(120, 90%, 30%, 0.6) 20%, transparent);
}

.card_inactive:before {
    opacity: 1;
    background-image: radial-gradient(ellipse at 50% 100%, var(--color-neutral-800) 20%, transparent);
}

.card-header {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > *:first-child {
        flex-grow: 1;
    }
    & h1,
    & h2,
    & h3 {
        font-weight: normal;
    }
}

.card-main {
    position: relative;
    flex-grow: 1;
    padding: 12px 16px;
    @media (min-width: 600px) {
        padding: 12px 16px;
    }
}
